import React from 'react'

import DatabasesContent from 'components/Databases'
import SEO from 'components/SEO'

const Databases = () => {
  return (
    <>
      <SEO title="Services" />
      <DatabasesContent />
    </>
  )
}

export default Databases
