import React from 'react'
import { usePageIconsImages } from 'hooks/usePageIconsImagesHook'

import Intro from 'elements/Intro'

const DatabasesContent = () => {
  const {
    databaseIcon: {
      childImageSharp: {
        gatsbyImageData: {
          images: {
            fallback: { src }
          }
        }
      }
    }
  } = usePageIconsImages()
  return (
    <section className="py-16">
      <div className="container mx-auto px-8">
        <Intro pageTitle="Databases" imgSrc={src} />

        <div className="flex bg-white py-5 my-12 shadow-news-card w-full max-w-982px rounded-lg select-none transition-shadow duration-300 hover:shadow-news-card-strong z-1 relative">
          <div className="flex flex-col justify-between text-sm bg-white px-5 my-3 rounded-lg">
            <span className="text-sm font-bold">Useful links</span>
            <ul className="text-sm text-vividBlue mt-5">
              <li className="mb-3">
                <a
                  className="hover:underline"
                  href="http://hr.china-embassy.org/eng/sgxx/zjsg/t1189540.htm"
                  target="_blank"
                >
                  Embassy of the People’s Republic of China in the Republic of Croatia
                </a>
              </li>

              <li className="mb-3">
                <a className="hover:underline" href="http://cn.mvep.hr/en/" target="_blank">
                  Embassy of Republic of Croatia in the People’s Republic of China
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://www.ki.unizg.hr" target="_blank">
                  Confucius Institute University of Zagreb
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://sinologija.ffzg.unizg.hr" target="_blank">
                  Sinology department (Faculty of Humanities and Social Sciences, University of Zagreb)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="https://www.fmprc.gov.cn/mfa_eng/" target="_blank">
                  Ministry of Foreign Affairs of the People’s Republic of China
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://en.moe.gov.cn" target="_blank">
                  Ministry of Education of People’s Republic of China
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://www.chinese.cn/page/#/pcpage/mainpage" target="_blank">
                  Confucius Institute Headquarters (Hanban)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="https://china-cee.eu" target="_blank">
                  China-CEE Institute
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://www.cseba.eu" target="_blank">
                  Chinese Southeast European Business Association (CSEBA; 中国东南欧洲商业协会)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="https://www.geoeconomic-forum.com/en/" target="_blank">
                  Geoeconomic forum (GEOFO)
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex bg-white py-5 my-12 shadow-news-card w-full max-w-982px rounded-lg select-none transition-shadow duration-300 hover:shadow-news-card-strong z-1 relative">
          <div className="flex flex-col justify-between text-sm bg-white px-5 my-3 rounded-lg">
            <span className="text-sm font-bold">List of Southeast Europe centers in China</span>
            <ul className="text-sm text-vividBlue mt-5">
              <li className="mb-3">
                <a className="hover:underline" href="http://euroasia.cssn.cn/xswz/xswz_zdowz/" target="_blank">
                  Central and East Europe Department of the Institute of Russian, Eastern European and Central Asian
                  Studies; Chinese Academy of Social Sciences (中国社会科学院俄罗斯东欧中亚研究所中东欧研究室)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="https://china-cee.eu" target="_blank">
                  Central and East Europe Department of the Institute of European Studies, Chinese Academy of Social
                  Sciences (中国社会科学院欧洲研究所中东欧研究室)
                </a>
              </li>
              <li className="mb-3">
                <a
                  className="hover:underline"
                  href="http://iwh.cssn.cn/en/institution/201608/t20160801_3144681.html"
                  target="_blank"
                >
                  Russia and East Europe Department of the Institute of World History, Chinese Academy of Social
                  Sciences (中国社会科学院世界历史研究所俄罗斯东欧史研究室)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://www.sis.pku.edu.cn/index.htm" target="_blank">
                  Central and Eastern Europe Research Center, Peking University (北京大学国际关系学院中东欧研究中心)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://history.cnu.edu.cn/kxyj/yjjg/128453.htm" target="_blank">
                  Center for Study of Civilizations (Southeast Europe Studies), Capital Normal University
                  (首都师范大学文明区划研究中心)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://www.bfsu.edu.cn/overview" target="_blank">
                  Balkan Region Research Center, Beijing Foreign Studies University (北京外国语大学巴尔干地区研究中心)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://www.nb-ceec.org.cn" target="_blank">
                  Ningbo CEEC Cooperation Institute (宁波中东欧国家合作研究院)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://cee.bisu.edu.cn" target="_blank">
                  Central and Eastern Europe Research Center, Beijing International Studies University
                  (北京第二外国语学院中东欧研究中心)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://www.zju.edu.cn" target="_blank">
                  Central and Eastern Europe Research Center, Zhejiang University (浙江大学中东欧研究中心)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="https://www.ecnu.edu.cn" target="_blank">
                  Central and Eastern Europe Research Center, East China Normal University (华东师范大学中东欧研究中心)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://www.blcu.edu.cn/index.html" target="_blank">
                  Central and Eastern Europe Research Center, Beijing Language and Culture University
                  (北京语言大学中东欧研究中心)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://www.njtu.edu.cn" target="_blank">
                  Central and Eastern Europe Research Center, Beijing Jiaotong University (北京交通大学中东欧研究中心)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://eng.suibe.edu.cn" target="_blank">
                  Central and Eastern Europe Research Center, Shanghai University of International Business and
                  Economics (上海对外经贸大学中东欧研究中心)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="https://www.tongji.edu.cn" target="_blank">
                  Central and Eastern Europe Research Center, Tongji University (同济大学中东欧研究中心)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://www.gdufs.edu.cn" target="_blank">
                  Central and Eastern Europe Research Center, Guangdong University of Foreign Studies
                  (广东外语外贸大学中东欧研究中心)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://www.bfsu.edu.cn/overview" target="_blank">
                  Eastern Europe Research Center, Beijing Foreign Studies University (北京外国语大学中东欧研究中心)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://www.cufe.edu.cn" target="_blank">
                  Russian and Central and Eastern Europe Research Center, Central University of Finance and Economics
                  (中央财经大学俄罗斯东欧中亚研究中心)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://www.tjut.edu.cn" target="_blank">
                  Belt and Road Central and East Europe Research Center, Tianjin University of Technology
                  (天津理工大学“一带一路”中东欧研究院)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://www.ljzfc.org/zh-hans/research/list/4" target="_blank">
                  Central and Eastern Europe Economy Institute, CEIBS Lujiazui Institute of International Finance
                  (中欧陆家嘴国际金融研究院中东欧经济研究所)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://cpcees.scu.edu.cn" target="_blank">
                  Center for Polish, Central &amp; Eastern European Studies, Sichuan University
                  (四川大学波兰与中东欧问题研究中心)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://www.sres.shisu.edu.cn" target="_blank">
                  School of Russian and Eurasian Studies, Shanghai International Studies University
                  (上海外国语大学俄罗斯东欧中亚学院)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="http://www.nuaa.edu.cn" target="_blank">
                  Balkan Region Research Center, Nanjing University of Aeronautics and Astronautics
                  (南京航空航天大学巴尔干地区研究中心)
                </a>
              </li>
              <li className="mb-3">
                <a className="hover:underline" href="https://www.swufe.edu.cn" target="_blank">
                  Southwestern University of Finance and Economics, Central and East Europe and Balkan Region Research
                  Center (西南财经大学中东欧与巴尔干地区研究中心)
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DatabasesContent
